import {ButtonPriority} from 'wix-ui-tpa';
import {Button} from 'wix-ui-tpa/cssVars';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {classes} from './CartButton.st.css';

export enum CartButtonDataHooks {
  root = 'CartButtonDataHooks.root',
}

export const CartButton = () => {
  const {t} = useTranslation();
  const {navigateToCart} = useControllerProps().navigationStore;

  return (
    <Button
      className={classes.button}
      data-hook={CartButtonDataHooks.root}
      priority={ButtonPriority.basicSecondary}
      onClick={() => navigateToCart()}
      upgrade
      fullWidth>
      {t('sideCart.goToCartPage.cta')}
    </Button>
  );
};
