import React from 'react';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {classes} from './Body.st.css';
import {CartItems} from '../../../../../common/components/CartItems/CartItems';
import {Text} from 'wix-ui-tpa';
import settingsParams from '../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';

export enum BodyDataHooks {
  emptyState = 'BodyDataHooks.emptyState',
}

export const Body = () => {
  const {shouldRenderEmptyState} = useControllerProps().cartStore;
  const {get: getSettings} = useSettings();
  const showQuantityBySettings = getSettings(settingsParams.CART_ITEM_QUANTITY_COUNTER_VISIBILITY) as boolean;
  const showThumbnailBySettings = getSettings(settingsParams.CART_ITEM_IMAGE_VISIBILITY) as boolean;
  const shouldShowItemInfoBySettings = getSettings(settingsParams.CART_ITEM_INFO) as boolean;

  return (
    <div>
      {shouldRenderEmptyState ? (
        <div className={classes.emptyState}>
          <Text data-hook={BodyDataHooks.emptyState} className={classes.emptyStateText}>
            {getSettings(settingsParams.CART_EMPTY_CART) as string}
          </Text>
        </div>
      ) : (
        <CartItems
          hideLastDivider={true}
          showQuantityBySettings={showQuantityBySettings}
          showThumbnailBySettings={showThumbnailBySettings}
          shouldShowItemInfoBySettings={shouldShowItemInfoBySettings}
        />
      )}
    </div>
  );
};
